<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" :title="fromTitle" @close="onClose">
    <a-form-model ref="form" :model="form" :rules="rules" v-if="reMountedForm">
      <a-form-model-item label="设备编号" prop="deviceId">
        <a-input v-model="form.deviceId" :disabled="readOnly" placeholder="请输入设备编号" />
      </a-form-model-item>
      <a-form-model-item label="所属部门" prop="deptId">
        <a-tree-select
          v-model="form.deptId"
          style="width: 100%"
          :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
          :tree-data="deptOptions"
          placeholder="请选择"
          :replaceFields="replaceFields"
          tree-default-expand-all
        >
        </a-tree-select>
      </a-form-model-item>
      <a-form-model-item label="设备类型" prop="deviceType">
        <a-select allowClear placeholder="请选择设备类型" v-model="form.deviceType">
          <a-select-option v-for="d in deviceTypeOptions" :key="d.dictValue" :value="d.dictValue">{{
            d.dictLabel
          }}</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="设备型号" prop="deviceModel">
        <a-input v-model="form.deviceModel" :disabled="readOnly" placeholder="请输入设备型号" />
      </a-form-model-item>
      <a-form-model-item label="IMEI号码" prop="imei">
        <a-input v-model="form.imei" placeholder="请输入IMEI号码" />
      </a-form-model-item>
      <a-form-model-item label="SIM卡号" prop="simNo">
        <a-input v-model="form.simNo" placeholder="请输入SIM卡号" />
      </a-form-model-item>
      <a-form-model-item label="启用状态" prop="status">
        <a-radio-group v-model="form.status" button-style="solid">
          <a-radio-button v-for="(d, index) in statusOptions" :key="index" :value="d.dictValue">{{
            d.dictLabel
          }}</a-radio-button>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item label="备注" prop="memo">
        <a-input v-model="form.memo" placeholder="请输入内容" type="textarea" allow-clear />
      </a-form-model-item>
      <a-form-model-item label="是否虚拟设备" prop="virtualDevice">
        <a-radio-group v-model="form.virtualDevice" button-style="solid">
          <a-radio-button v-for="(d, index) in virtualeOptions" :key="index" :value="d.dictValue">{{
            d.dictLabel
          }}</a-radio-button>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item v-if="form.virtualDevice === '1'" label="默认经度(lng)" prop="lng">
        <a-input v-model="form.lng" placeholder="请输入默认经度" />
      </a-form-model-item>
      <a-form-model-item v-if="form.virtualDevice === '1'" label="默认纬度(lat)" prop="lat">
        <a-input v-model="form.lat" placeholder="请输入默认纬度" />
      </a-form-model-item>
      <a-form-model-item v-if="form.virtualDevice === '1'" label="默认转向" prop="virtualHeading">
        <a-input v-model="form.virtualHeading" placeholder="请输入默认转向" />
      </a-form-model-item>
      <a-form-model-item v-if="form.virtualDevice === '1'" label="随机里程" prop="odoDevice">
        <a-input v-model="form.odoDevice" placeholder="请输入随机里程" />
      </a-form-model-item>
      <a-form-model-item v-if="form.virtualDevice === '1'" label="绑定真实设备ID" prop="bindDeviceId">
        <a-input v-model="form.bindDeviceId" placeholder="请输入绑定真实设备ID" />
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="saveLoading" @click="submitForm"> 保存 </a-button>
          <a-button type="dashed" @click="cancel"> 取消 </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getDeviceInfo, addDeviceInfo, updateDeviceInfo } from '@/api/jt808/deviceInfo'
import { TreeSelect } from 'ant-design-vue'

export default {
  name: 'CreateForm',
  props: {
    deviceTypeOptions: {
      type: Object,
      default: () => {}
    },
    deptOptions: {
      type: Array,
      required: true
    },
    statusOptions: {
      type: Array,
      required: true
    }
  },
  components: {
    ATreeSelect: TreeSelect
  },
  data() {
    return {
      reMountedForm: true,
      readOnly: true,
      loading: false,
      saveLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,
        deviceId: null,
        deptId: undefined,
        imei: null,
        deviceType: undefined,
        deviceModel: null,
        simNo: null,
        status: '0',
        memo: null,
        bindDeviceId: null,
        virtualDevice: null,
        lng: null,
        lat: null,
        virtualHeading: null,
        odoDevice: null
      },
      // 1增加,2修改
      formType: 1,
      open: false,
      replaceFields: { children: 'children', title: 'label', key: 'id', value: 'id' },
      rules: {
        deviceId: [{ required: true, message: '设备编号不能为空', trigger: 'blur' }],
        deviceType: [{ required: true, message: '设备类型不能为空', trigger: 'blur' }],
        virtualDevice: [{ required: true, message: '请选择是否虚拟车辆', trigger: 'change' }]
      },
      virtualeOptions: [
        { dictValue: '1', dictLabel: '是' },
        { dictValue: '0', dictLabel: '否' }
      ]
    }
  },
  filters: {},
  created() {},
  computed: {},
  watch: {
    open(val) {
      if (val) {
        this.reMountedForm = false
        this.$nextTick(() => {
          this.reMountedForm = true
        })
      }
    },
    'form.virtualDevice'(val) {
      console.log('virtualDevice', val)
      if (val === 1) {
        this.rules = {
          deviceId: [{ required: true, message: '设备编号不能为空', trigger: 'blur' }],
          deviceType: [{ required: true, message: '设备类型不能为空', trigger: 'blur' }],
          virtualDevice: [{ required: true, message: '请选择是否虚拟车辆', trigger: 'change' }],
          lng: [{ required: true, message: '经度不能为空', trigger: 'blur' }],
          lat: [{ required: true, message: '维度不能为空', trigger: 'blur' }],
          bindDeviceId: [{ required: true, message: '绑定真实车辆编号不能为空', trigger: 'blur' }]
        }
      } else {
        this.rules = {
          deviceId: [{ required: true, message: '设备编号不能为空', trigger: 'blur' }],
          deviceType: [{ required: true, message: '设备类型不能为空', trigger: 'blur' }],
          virtualDevice: [{ required: true, message: '请选择是否虚拟车辆', trigger: 'change' }]
        }
      }
    }
  },
  mounted() {},
  methods: {
    onClose() {
      this.open = false
    },
    // 取消按钮
    cancel() {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset() {
      this.formType = 1
      this.saveLoading = false
      this.form = {
        id: null,
        deptId: undefined,
        deviceId: null,
        imei: null,
        deviceType: undefined,
        deviceModel: null,
        status: '0',
        simNo: null,
        memo: null,
        bindDeviceId: null,
        virtualDevice: null,
        lng: null,
        lat: null,
        virtualHeading: null,
        odoDevice: null
      }
    },
    /** 新增按钮操作 */
    handleAdd(row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.readOnly = false
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate(row, ids, type) {
      this.reset()
      this.formType = 2
      this.readOnly = true
      // if (type === 'read') {
      //   this.readOnly = true
      // } else {
      //   this.readOnly = false
      // }
      const id = row ? row.id : ids
      getDeviceInfo(id).then((response) => {
        this.form = response.data
        this.form.deviceType = this.form.deviceType ? this.form.deviceType + '' : undefined
        this.form.deptId = this.form.deptId ? this.form.deptId + '' : undefined
        for (const key in this.form) {
          if (typeof this.form[key] === 'number') {
            this.form[key] = this.form[key] + ''
          }
        }
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.saveLoading = true
          const form = JSON.parse(JSON.stringify(this.form))
          if (form.virtualDevice !== '1') {
            delete form.lng
            delete form.lat
            delete form.bindVehicleId
            delete form.odoDevice
            delete form.virtualHeading
          }
          if (form.id !== undefined && form.id !== null) {
            updateDeviceInfo(form)
              .then((response) => {
                this.saveLoading = false
                this.$message.success('修改成功', 3)
                this.open = false
                this.$emit('ok')
              })
              .catch(() => {
                this.saveLoading = false
              })
          } else {
            addDeviceInfo(form)
              .then((response) => {
                this.saveLoading = false
                this.$message.success('新增成功', 3)
                this.open = false
                this.$emit('ok')
              })
              .catch(() => {
                this.saveLoading = false
              })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>

/*
 * @Auth: linjituan
 * @Date: 2021-11-11 10:32:55
 * @LastEditors: linjituan
 * @LastEditTime: 2022-01-11 19:32:00
 */
import request from '@/utils/request'
const baseURL = '/jt-api'

// 查询设备终端信息列表
export function listDeviceInfo(query) {
  return request({
    url: '/deviceInfo/list',
    method: 'get',
    baseURL,
    params: query
  })
}

// 查询设备终端信息详细
export function getDeviceInfo(id) {
  return request({
    url: '/deviceInfo/' + id,
    baseURL,
    method: 'get'
  })
}

// 新增设备终端信息
export function addDeviceInfo(data) {
  return request({
    url: '/deviceInfo',
    method: 'post',
    baseURL,
    data: data
  })
}

// 修改设备终端信息
export function updateDeviceInfo(data) {
  return request({
    url: '/deviceInfo',
    method: 'put',
    baseURL,
    data: data
  })
}

// 删除设备终端信息
export function delDeviceInfo(id) {
  return request({
    url: '/deviceInfo/' + id,
    baseURL,
    method: 'delete'
  })
}

// 导出设备终端信息
export function exportDeviceInfo(query) {
  return request({
    url: '/deviceInfo/export',
    baseURL,
    method: 'get',
    params: query
  })
}
// 模版下载接口
export function importTemplate() {
  return request({
    url: '/deviceInfo/importTemplate',
    baseURL,
    responseType: 'blob',
    method: 'get'
  })
}

// 导入设备终端信息
export function importData(data) {
  return request({
    url: '/deviceInfo/importData',
    baseURL,
    method: 'post',
    data: data
  })
}

// 查询设备终端信息列表
export function searchDevice(query) {
  return request({
    url: '/deviceInfo/search',
    method: 'get',
    baseURL,
    params: query
  })
}

export function parameters(query) {
  return request({
    url: '/terminal/parameters',
    method: 'get',
    baseURL,
    params: query
  })
}

export function setParameters(data, clientId) {
  return request({
    url: '/terminal/parameters?clientId=' + clientId,
    method: 'put',
    baseURL,
    data
  })
}

// 根据若干设备id查询各设备在线状态
export function queryOnline(data) {
  return request({
    url: '/deviceStatus/queryOnline',
    baseURL,
    method: 'post',
    data: data
  })
}

export function terminalControl(data, clientId) {
  return request({
    url: '/terminal/control?clientId=' + clientId,
    method: 'put',
    baseURL,
    data
  })
}

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-drawer",
    {
      attrs: {
        width: "35%",
        "label-col": 4,
        "wrapper-col": 14,
        visible: _vm.open,
        title: _vm.fromTitle,
      },
      on: { close: _vm.onClose },
    },
    [
      _vm.reMountedForm
        ? _c(
            "a-form-model",
            { ref: "form", attrs: { model: _vm.form, rules: _vm.rules } },
            [
              _c(
                "a-form-model-item",
                { attrs: { label: "设备编号", prop: "deviceId" } },
                [
                  _c("a-input", {
                    attrs: {
                      disabled: _vm.readOnly,
                      placeholder: "请输入设备编号",
                    },
                    model: {
                      value: _vm.form.deviceId,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "deviceId", $$v)
                      },
                      expression: "form.deviceId",
                    },
                  }),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "所属部门", prop: "deptId" } },
                [
                  _c("a-tree-select", {
                    staticStyle: { width: "100%" },
                    attrs: {
                      "dropdown-style": {
                        maxHeight: "400px",
                        overflow: "auto",
                      },
                      "tree-data": _vm.deptOptions,
                      placeholder: "请选择",
                      replaceFields: _vm.replaceFields,
                      "tree-default-expand-all": "",
                    },
                    model: {
                      value: _vm.form.deptId,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "deptId", $$v)
                      },
                      expression: "form.deptId",
                    },
                  }),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "设备类型", prop: "deviceType" } },
                [
                  _c(
                    "a-select",
                    {
                      attrs: { allowClear: "", placeholder: "请选择设备类型" },
                      model: {
                        value: _vm.form.deviceType,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "deviceType", $$v)
                        },
                        expression: "form.deviceType",
                      },
                    },
                    _vm._l(_vm.deviceTypeOptions, function (d) {
                      return _c(
                        "a-select-option",
                        { key: d.dictValue, attrs: { value: d.dictValue } },
                        [_vm._v(_vm._s(d.dictLabel))]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "设备型号", prop: "deviceModel" } },
                [
                  _c("a-input", {
                    attrs: {
                      disabled: _vm.readOnly,
                      placeholder: "请输入设备型号",
                    },
                    model: {
                      value: _vm.form.deviceModel,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "deviceModel", $$v)
                      },
                      expression: "form.deviceModel",
                    },
                  }),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "IMEI号码", prop: "imei" } },
                [
                  _c("a-input", {
                    attrs: { placeholder: "请输入IMEI号码" },
                    model: {
                      value: _vm.form.imei,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "imei", $$v)
                      },
                      expression: "form.imei",
                    },
                  }),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "SIM卡号", prop: "simNo" } },
                [
                  _c("a-input", {
                    attrs: { placeholder: "请输入SIM卡号" },
                    model: {
                      value: _vm.form.simNo,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "simNo", $$v)
                      },
                      expression: "form.simNo",
                    },
                  }),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "启用状态", prop: "status" } },
                [
                  _c(
                    "a-radio-group",
                    {
                      attrs: { "button-style": "solid" },
                      model: {
                        value: _vm.form.status,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "status", $$v)
                        },
                        expression: "form.status",
                      },
                    },
                    _vm._l(_vm.statusOptions, function (d, index) {
                      return _c(
                        "a-radio-button",
                        { key: index, attrs: { value: d.dictValue } },
                        [_vm._v(_vm._s(d.dictLabel))]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "备注", prop: "memo" } },
                [
                  _c("a-input", {
                    attrs: {
                      placeholder: "请输入内容",
                      type: "textarea",
                      "allow-clear": "",
                    },
                    model: {
                      value: _vm.form.memo,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "memo", $$v)
                      },
                      expression: "form.memo",
                    },
                  }),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "是否虚拟设备", prop: "virtualDevice" } },
                [
                  _c(
                    "a-radio-group",
                    {
                      attrs: { "button-style": "solid" },
                      model: {
                        value: _vm.form.virtualDevice,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "virtualDevice", $$v)
                        },
                        expression: "form.virtualDevice",
                      },
                    },
                    _vm._l(_vm.virtualeOptions, function (d, index) {
                      return _c(
                        "a-radio-button",
                        { key: index, attrs: { value: d.dictValue } },
                        [_vm._v(_vm._s(d.dictLabel))]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm.form.virtualDevice === "1"
                ? _c(
                    "a-form-model-item",
                    { attrs: { label: "默认经度(lng)", prop: "lng" } },
                    [
                      _c("a-input", {
                        attrs: { placeholder: "请输入默认经度" },
                        model: {
                          value: _vm.form.lng,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "lng", $$v)
                          },
                          expression: "form.lng",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.form.virtualDevice === "1"
                ? _c(
                    "a-form-model-item",
                    { attrs: { label: "默认纬度(lat)", prop: "lat" } },
                    [
                      _c("a-input", {
                        attrs: { placeholder: "请输入默认纬度" },
                        model: {
                          value: _vm.form.lat,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "lat", $$v)
                          },
                          expression: "form.lat",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.form.virtualDevice === "1"
                ? _c(
                    "a-form-model-item",
                    { attrs: { label: "默认转向", prop: "virtualHeading" } },
                    [
                      _c("a-input", {
                        attrs: { placeholder: "请输入默认转向" },
                        model: {
                          value: _vm.form.virtualHeading,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "virtualHeading", $$v)
                          },
                          expression: "form.virtualHeading",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.form.virtualDevice === "1"
                ? _c(
                    "a-form-model-item",
                    { attrs: { label: "随机里程", prop: "odoDevice" } },
                    [
                      _c("a-input", {
                        attrs: { placeholder: "请输入随机里程" },
                        model: {
                          value: _vm.form.odoDevice,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "odoDevice", $$v)
                          },
                          expression: "form.odoDevice",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.form.virtualDevice === "1"
                ? _c(
                    "a-form-model-item",
                    {
                      attrs: { label: "绑定真实设备ID", prop: "bindDeviceId" },
                    },
                    [
                      _c("a-input", {
                        attrs: { placeholder: "请输入绑定真实设备ID" },
                        model: {
                          value: _vm.form.bindDeviceId,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "bindDeviceId", $$v)
                          },
                          expression: "form.bindDeviceId",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "div",
                { staticClass: "bottom-control" },
                [
                  _c(
                    "a-space",
                    [
                      _c(
                        "a-button",
                        {
                          attrs: { type: "primary", loading: _vm.saveLoading },
                          on: { click: _vm.submitForm },
                        },
                        [_vm._v(" 保存 ")]
                      ),
                      _c(
                        "a-button",
                        {
                          attrs: { type: "dashed" },
                          on: { click: _vm.cancel },
                        },
                        [_vm._v(" 取消 ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }